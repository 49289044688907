import store from "../store"

const init_cart = async () => {
    const user = store.getters.user
    if(user && user.assortment){
        store.state.client_cart.assortment = user.assortment;
    }
    if(user && user.client){
        store.state.client_cart.client = user.client;
    }
    if(user){
        store.state.client_cart.user_uid = user.uid
    }
    if(store.state.client_cart.items.length > 0){
        store.state.client_cart.items.forEach(item => {
            let inx = store.state.products.findIndex(it => it.makat == item.makat)
            if(inx != -1){
                store.state.products[inx] = item;
            }
        })
    }
}

const add_item_to_cart = async (item, amount) => {
    let products = store.state.products
    let inx = products.findIndex(_item => {
        return _item.makat == item.makat
    })
    if(inx != -1){
        products[inx].order_amount = amount
        if(Number(amount) == 0 && products[inx].sale_price){
            delete products[inx].sale_price
        }
        if(Number(amount) == 0 && products[inx].discount){
            delete products[inx].discount
            delete products[inx].discount_price
        }
    }
    update_cart_items();
    console.log(store.state.client_cart);
}

const update_cart_items = async () => {
    let products = store.state.products
    store.state.client_cart.items = products.filter(itm => {
        return itm.order_amount && itm.order_amount > 0
    })

    if(store.state.client_cart.assortment == '10001' || store.state.client_cart.assortment == '10002'){
        store.state.client_cart.items.forEach(item => {
            // check Sales
            if(item.sale && item.sale.approved){
                let total_amount = item.order_amount
                if(item.sale.makats_arr.length > 1){
                    item.sale.makats_arr.forEach(makat => {
                        let inx = store.state.client_cart.items.findIndex(itm => {
                            return itm.makat == makat && itm.makat != item.makat
                        })
                        if(inx != -1){
                            total_amount += store.state.client_cart.items[inx].order_amount
                        }
                    })
                }

                let inx = store.state.products.findIndex(itm => itm.makat == item.makat)

                if(Number(item.sale.step_1.amount) > 0){
                    if(total_amount >= Number(item.sale.step_1.amount)){
                        store.state.products[inx].sale_price = Number(item.sale.step_1.price)
                    }else{
                        delete store.state.products[inx].sale_price
                    }
                }
                if(Number(item.sale.step_2.amount) > 0){
                    if(total_amount >= Number(item.sale.step_2.amount)){
                        store.state.products[inx].sale_price = Number(item.sale.step_2.price)
                    }
                }
                if(Number(item.sale.step_3.amount) > 0){
                    if(total_amount >= Number(item.sale.step_3.amount)){
                        store.state.products[inx].sale_price = Number(item.sale.step_3.price)
                    }
                }
                if(Number(item.sale.step_4.amount) > 0){
                    if(total_amount >= Number(item.sale.step_4.amount)){
                        store.state.products[inx].sale_price = Number(item.sale.step_4.price)
                    }
                }
            }
            // check discounts
            if(item.prices && item.prices.length > 0){
                let total_amount = Number(item.order_amount);
                let merakez_items = store.state.products.filter(itm => {
                    return itm.paritMerakez == item.paritMerakez && itm.makat != item.makat && itm.order_amount > 0
                })
                merakez_items.forEach(merakez_item => {
                    total_amount += Number(merakez_item.order_amount)
                })

                let inx = store.state.products.findIndex(itm => itm.makat == item.makat)
                item.prices.forEach(price => {
                    if(total_amount >= price.min){
                        store.state.products[inx].discount = price.discount
                        store.state.products[inx].discount_price = Number(item.basePrice) * ( 1 - price.discount/100)
                    }
                })
                if(item.prices[0].min > total_amount){
                    delete store.state.products[inx].discount
                    delete store.state.products[inx].discount_price
                }

            }
        })

        
    }
}

const clear_cart = async () => {

}

const save_cart_to_clound = async () => {

}

export{init_cart, add_item_to_cart}

