<template>
    <div class="client-cart">
        <ItemToCart v-if="selected_item" :product="selected_item" @close="selected_item = null"/>
        <div class="cart-win">
            <div class="title">
                <div class="close-cart" @click="close">
                    <i class="material-icons">close</i>
                </div>
                <p>הזמנה</p>
            </div>
            <div class="items-list">
                <template v-for="item in items_computed" :key="item.makat">
                    <div class="item-box">
                        <div class="item-top">
                            <div class="item-image">
                                <div class="edit-item" @click="selected_item = item">
                                    <i class="material-icons" style="font-size:15px; color:white; text-shadow: 0 0 3px grey;">edit</i>
                                </div>
                                <img :src="item.image" alt="">
                            </div>
                            <div class="item-info">
                                <p class="item-name">{{item.name}}</p>
                                <p>ברקוד: {{item.barcode}}</p>
                                <p>מק"ט: {{item.makat}}</p>
                                <p>הערה: {{item.note}}</p>
                            </div>
                        </div>
                        <div class="item-bottom">
                            <div class="item-amount">
                                <p style="margin-left:5px;">כמות:</p>
                                <p style="margin-left:5px;">{{item.order_amount}}</p>
                                <p>יח'</p>
                            </div>
                            <div class="item-price" v-if="see_prices">
                                <p style="margin-left:5px;">מחיר:</p>
                                <p style="margin-left:5px;">{{get_item_price(item).toFixed(2)}}</p>
                                <p>יח'</p> 
                            </div>
                            <div class="item-sale" v-if="item.sale_price">
                                <p>מבצע</p>
                            </div>
                            <div class="item-discount" v-if="item.discount_price">
                                <p>הנחת כמות</p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="cart-bottom">
                <el-button type="success" style="margin:0; width:60%;" @click="go_to_checkout">סיים הזמנה</el-button>
                <el-button type="danger" style="margin:0; width:30%;">רוקן הזמנה</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import ItemToCart from '../Catalog/AddItemToCart.vue'
import store from '../../store'
import router from '../../router'
export default {
emits:["close"],
components:{ItemToCart},
setup(_, {emit}){
    let cart = ref(computed(() => {
        return store.state.client_cart
    }))

    const items_computed = ref(computed(() => {
        return cart.value.items
    }))

    const close = () => {
        emit("close");
    }

    const see_prices = ref(computed(() => {
        return store.getters.client_cart.prices
    }))

    const get_item_price = (item) => {
        if(item.sale_price){
            return item.sale_price
        }
        if(item.discount_price){
            return item.discount_price
        }
        return item.basePrice
    }
    const selected_item = ref(null);

    console.log(cart.value);

    const go_to_checkout = () => {
        router.push('/checkout')
        emit("close")
    }

    return{
        cart, close, items_computed, selected_item, see_prices,get_item_price, go_to_checkout
    }
}
}
</script>

<style scoped>
.client-cart{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.767);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-win{
    width: 95%;
    max-width: 500px;
    height: 95%;
    max-height: 735px;
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(31, 31, 31, 0.863);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
}
.title{
    width: 100%;
    background: var(--success);
    color: whitesmoke;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.664);
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    position: relative;
}
.close-cart{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 5px grey;
}
.items-list{
    width: 100%;
    padding: 5px;
    height: calc(100% - 50px - 50px);
    overflow-y: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}
.item-box{
    width: 100%;
    height: 120px;
    background: whitesmoke;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 5px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.item-top{
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    height: 80px;
}
.item-image{
    position: relative;
    width: 80px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.132);
}
.item-info{
    width: calc(100% - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3px;
    overflow: hidden;
    font-size: 14px;
}
.cart-bottom{
    width: 100%;
    height: 50px;
    padding: 5px;
    background: var(--main);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.item-name{
    font-size: 18px;
    text-shadow: 0 0 3px rgba(212, 212, 212, 0.203);
    text-align: center;
    flex-shrink: 0;
    white-space: nowrap;
}
.edit-item{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--warning);
    box-shadow: 0 0 3px grey;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.item-bottom{
    margin-top: 3px;
    width: 100%;
    height: 27px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--purple);
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.755);
    border-radius: 5px;
    overflow: hidden;
}
.item-amount{
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.item-price{
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.item-sale{
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--danger);
}
.item-discount{
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--danger);
}
@media only screen and (max-width: 600px) {
    .item-name{
        font-size: 16px;
    }
}
</style>