<template>
    <div class="add-item-back" @click="close">
        <div class="add-item-to-cart">

            <div class="agent-options" v-if="agent_options">
                <div class="agent-options-title">
                    <div class="close-agent-options" @click="agent_options =!agent_options">
                        <i class="material-icons">arrow_back_ios_new</i>
                    </div>
                    <p>{{product.name}}</p>
                </div>
                <div class="agent-option-line">
                    <p>מלאי זמין</p>
                    <p v-if="agent_product_data.stock">{{agent_product_data.stock}} יח'</p>
                    <i v-if="!agent_product_data.stock" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="agent-option-line" v-if="product.last_arrival">
                    <p>כניסה אחרונה</p>
                    <p>{{new Date(product.last_arrival).toLocaleDateString('he')}}</p>
                </div>
                <div class="agent-option-line">
                    <p>מלאי בדרך</p>
                    <p v-if="agent_product_data.shipments">{{agent_product_data.shipments}} יח'</p>
                    <i v-if="!agent_product_data.shipments" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="agent-options-title">
                    <p>פרטי לקוח</p>
                </div>
                <div class="agent-option-line">
                    <p>הזמנה אחרונה</p>
                    <p v-if="agent_product_data.last_bought_date">{{agent_product_data.last_bought_date}}</p>
                    <i v-if="!agent_product_data.last_bought_date" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="agent-option-line">
                    <p>כמות</p>
                    <p v-if="agent_product_data.last_bought_amount">{{agent_product_data.last_bought_amount}} יח'</p>
                    <i v-if="!agent_product_data.last_bought_amount" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="agent-option-line">
                    <p>במחיר</p>
                    <p v-if="agent_product_data.last_bought_price">{{agent_product_data.last_bought_price}} ש"ח ליח'</p>
                    <i v-if="!agent_product_data.last_bought_price" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="agent-option-line">
                    <p>הנחה</p>
                    <p v-if="agent_product_data.last_bought_discount">{{agent_product_data.last_bought_discount}} %</p>
                    <i v-if="!agent_product_data.last_bought_discount" class="el-icon-loading" style="width:50%; text-align:center;"></i>
                </div>
                <div class="last-orders-grid">
                    <template v-for="order in agent_product_data.last_orders" :key="order.DOC">
                        <div class="client-order">
                            <p>תאריך: {{new Date(order.CURDATE).toLocaleDateString('he')}}</p>
                            <p>כמות: {{order.QUANT}}</p>
                            <p>במחיר: {{order.ZROS_PRICE}} , הנחה: {{order.ZROS_PERCENT}}%</p>
                        </div>
                    </template>
                </div>
            </div>

            <div class="agent-options-btn" v-if="user && Number(user.agent) > 0" @click="agent_options = !agent_options">
                <i class="material-icons">settings_applications</i>
            </div>
             <div class="upload-img-btn" @click="handle_upload_new_image">
                    <i class="material-icons">add_a_photo</i>
                </div>

            <div class="close-btn" @click="close">
                <i class="material-icons close">close</i>
            </div>

            <div class="product-on-sale" v-if="product.sale">
                <p>פריט במבצע</p>
            </div>

            <div class="product-name">
                {{product.name}}
            </div>

            <div class="product-image">
                <div class="product-price" v-if="see_prices  && !product.sale_price">
                    <p v-if="!product.special_price"><span style="font-size:20px;">₪</span>{{product.basePrice}}</p>
                </div>
                <div class="product-price sale-price" v-if="see_prices && product.sale_price">
                    <p v-if="product.sale_price"><span style="font-size:20px;">₪</span>{{product.sale_price.toFixed(2)}}</p>
                    <p style="font-size:14px; text-align:center;">במקום {{product.basePrice}} ₪</p>
                </div>
                <div class="product-price sale-price" v-if="see_prices && product.discount_price">
                    <p v-if="product.discount_price"><span style="font-size:20px;">₪</span>{{product.discount_price.toFixed(2)}}</p>
                    <p style="font-size:14px; text-align:center;">במקום {{product.basePrice}} ₪</p>
                </div>
                
                <img v-if="!gallery" :src="product.image" alt="">
                <img v-else @click="show_light_box=true" :src="gallery[gallery_location]" style="cursor:pointer;" alt="">

               

                <div v-if="gallery" @click="handle_gallery_location('plus')" class="left-arrow">
                    <i class="material-icons">navigate_next</i>
                </div>

                <div v-if="gallery" @click="handle_gallery_location('minus')" class="right-arrow">
                    <i class="material-icons">chevron_left</i>
                </div>
              
            
            </div>

            <div class="product-data">
                <div class="data-line">
                    <p>מק"ט רוסמן: {{product.makat}}</p>
                </div>  
                <div class="data-line">
                    <p>ברקוד : {{product.barcode}}</p>
                </div>  
                <div class="data-line">
                    <p>כמות באריזה : {{product.boxSize}} {{product.measure}}</p>
                </div>  
                <div class="data-line">
                    <p>כשרות : {{product.kosher_type}}</p>
                    <i style="background:var(--alert-purple); border-radius: 3px; padding: 0 5px 0 5px; margin-right:15px; cursor:pointer;" 
                        class="material-icons" v-if="product.kosher_type && !product.kosher_type.includes('לא')" @click="get_item_kosher_doc">attach_file</i>
                </div>
            </div>

            <div class="adding-type">
                <p>הוסף כמות לפי:</p>
                <div class="add-type-btn" id="add-cartons" @click="adding_type = 'cartons'" :style="check_adding_type('cartons')">קרטונים</div>
                <div class="add-type-btn" id="add-pcs" @click="adding_type = 'pcs'" :style="check_adding_type('pcs')">יחידות</div>
            </div>
            <div class="add-amount">
                <el-input placeholder="Please input" v-model="order_amount_pcs" type="number" v-if="adding_type == 'pcs'" style="width:70%; height:100%; margin-left: 10px;"
                :input-style="{'text-align': 'center', 'font-size': '30px', 'height':'100%'}" @focus="clean_zeros"/>

                <el-input placeholder="Please input" v-model="order_amount_cartons" type="number" pattern="^\d*(\.\d{0,2})?$" v-if="adding_type == 'cartons'" 
                    style="width:70%; height:100%; margin-left: 10px;" :input-style="{'text-align': 'center', 'font-size': '30px', 'height':'100%'}" @focus="clean_zeros"/>

                <el-button type="success" icon="el-icon-check" circle @click="update_order_amount"></el-button>
            </div>
            <div class="note">
                <p>המחירים אינם כוללים מע"מ ופקדון*</p>
                <p v-if="product.pikadon && product.pikadon > 0">תוספת פקדון ליח': {{Number(product.pikadon * 0.3).toFixed(2)}} ₪</p>
            </div>
            <div class="product-sale" v-if="product.sale">
                <div class="sale-label">
                    <p>מבצע</p>
                    <div class="sale-end-date">
                        <p>עד {{product.sale.date_end_date.toDate().toLocaleDateString('he')}}</p>
                    </div>
                </div>
                <div class="sale-description">
                    <p>{{product.sale.description}}</p>
                </div>
                <div class="sale-prices-table">
                    <table id="table">
                        <tr>
                            <th>מדרגה</th>
                            <th>תיאור</th>
                            <th>כמות מינ.</th>
                            <th v-if="see_prices">מחיר ליח</th>
                            <th v-if="see_prices">מחיר כולל</th>
                        </tr>
                        <tr v-if="Number(product.sale.step_1.amount)">
                            <td>1</td>
                            <td>{{product.sale.step_1.description}}</td>
                            <td>{{Number(product.sale.step_1.amount)}}[{{Number(product.sale.step_1.amount / product.boxSize).toFixed(2)}}]</td>
                            <td v-if="see_prices">{{Number(product.sale.step_1.price).toFixed(2)}}</td>
                            <td v-if="see_prices">{{(Number(product.sale.step_1.price)*1.17).toFixed(2)}}</td>
                        </tr>
                        <tr v-if="Number(product.sale.step_2.amount)">
                            <td>2</td>
                            <td>{{product.sale.step_2.description}}</td>
                            <td>{{Number(product.sale.step_2.amount)}}[{{Number(product.sale.step_2.amount / product.boxSize).toFixed(2)}}]</td>
                            <td v-if="see_prices">{{Number(product.sale.step_2.price).toFixed(2)}}</td>
                            <td v-if="see_prices">{{(Number(product.sale.step_2.price)*1.17).toFixed(2)}}</td>
                        </tr>
                        <tr v-if="Number(product.sale.step_3.amount)">
                            <td>3</td>
                            <td>{{product.sale.step_3.description}}</td>
                            <td>{{Number(product.sale.step_3.amount)}}[{{Number(product.sale.step_3.amount / product.boxSize).toFixed(2)}}]</td>
                            <td v-if="see_prices">{{Number(product.sale.step_3.price).toFixed(2)}}</td>
                            <td v-if="see_prices">{{(Number(product.sale.step_3.price)*1.17).toFixed(2)}}</td>
                        </tr>
                        <tr v-if="Number(product.sale.step_4.amount)">
                            <td>2</td>
                            <td>{{product.sale.step_4.description}}</td>
                            <td>{{Number(product.sale.step_4.amount)}}[{{Number(product.sale.step_4.amount / product.boxSize).toFixed(2)}}]</td>
                            <td v-if="see_prices">{{Number(product.sale.step_4.price).toFixed(2)}}</td>
                            <td v-if="see_prices">{{(Number(product.sale.step_4.price)*1.17).toFixed(2)}}</td>
                        </tr>
                    </table>
                </div>
                <p class="more-sale-items-title" v-if="items_in_sale.length > 0">המבצע כולל גם את הפריטים הבאים:</p>
                <div class="more-products-on-sale">
                    <template v-for="item in items_in_sale" :key="item.makat">
                        <div class="item-card-sale" @click="open_new_item(item)">
                            <div class="order-amount" v-if="item.order_amount > 0">
                                <p>{{item.order_amount}}</p>
                                <p style="font-size: 10px;">יחידות</p>
                            </div>
                            <div class="pr-sl-makat">
                                {{item.makat}}
                            </div>
                            <div class="item-sale-card-image">
                                <img :src="item.image" alt="">
                            </div>
                            <p style="text-align:center; font-size: 14px; white-space:nowrap; width:100%; overflow:hidden;">{{item.name}}</p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="product-sale" v-if="product.prices && product.prices.length > 0">
                <div class="sale-label">
                    <p>הנחת כמות</p>
                </div>
                <div class="sale-prices-table">
                    <table id="table">
                        <tr>
                            <th>מדרגה</th>
                            <th>כמות מינ. ביח'</th>
                            <th v-if="see_prices">אחוז הנחה</th>
                            <th v-if="see_prices">מחיר לפני</th>
                            <th v-if="see_prices">מחיר כולל</th>
                        </tr>
                        <template v-for="(price,i) in product.prices" :key="i">
                            <tr>
                                <td>{{i}}</td>
                                <td>{{price.min}} [{{Number(price.min / product.boxSize).toFixed(2)}}]</td>
                                <td>{{price.discount}}%</td>
                                <td>{{(Number(product.basePrice) * (1 - price.discount/100)).toFixed(2)}}</td>
                                <td>{{(Number(product.basePrice) * (1 - price.discount/100) * 1.17).toFixed(2)}}</td>
                            </tr>
                        </template>
                    </table>
                </div>
                <p class="more-sale-items-title" v-if="items_on_discount && items_on_discount.length > 0">הנחת הכמות כוללת גם את הפריטים הבאים:</p>
                <div class="more-products-on-sale">
                    <template v-for="item in items_on_discount" :key="item.makat">
                        <div class="item-card-sale" @click="open_new_item(item)">
                            <div class="order-amount" v-if="item.order_amount > 0">
                                <p>{{item.order_amount}}</p>
                                <p style="font-size: 10px;">יחידות</p>
                            </div>
                            <div class="pr-sl-makat">
                                {{item.makat}}
                            </div>
                            <div class="item-sale-card-image">
                                <img :src="item.image" alt="">
                            </div>
                            <p style="text-align:center; font-size: 14px; white-space:nowrap; width:100%; overflow:hidden;">{{item.name}}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div v-if="show_light_box" class="light-box">
            <div class="images-box">
                <img :src="gallery[gallery_location]" alt="">
                <div v-if="gallery" @click="handle_gallery_location('plus')" class="left-arrow">
                    <i class="material-icons">navigate_next</i>
                </div>

                <div v-if="gallery" @click="handle_gallery_location('minus')" class="right-arrow">
                    <i class="material-icons">chevron_left</i>
                </div>
            </div>
            <div @click.stop="show_light_box=false" class="close-light-box">
                <i class="material-icons close">close</i>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/runtime-core';
import { slide_pop_error,alert } from '../../Methods/Msgs';
import store from '../../store';
import { projectFunctions} from '../../firebase/config';
import { add_item_to_cart } from '../../Methods/Cart';
import use_general_storage from '../../Methods/use_general_storage'
import Swal from 'sweetalert2'

export default {
props:['product'],
emits:["close", "open_new"],
setup(props, {emit}){
    const agent_name = ref(computed(()=>{
        return store.state.user.display_name
    }))
    const agent_id = ref(computed(()=>{
        return store.state.user.agent
    }))

    const {uploadImage,url} = use_general_storage()

    const agent_options = ref(false);

    const agent_product_data = ref({
        stock: null,
        shipments: null,
        files: null,
        last_bought_date: null,
        last_bought_amount: null,
        last_bought_price: null
    })
    
    const adding_type = ref('cartons')

    if(!props.product.order_amount){
        props.product.order_amount = 0;
    }

    const see_prices = ref(computed(() => {
        return store.getters.client_cart.prices
    }))

    const user = ref(computed(() => {
        return store.getters.user
    }))

    const merakez_active = ref(false);

    const items_on_discount = ref(computed(() => {
        if(merakez_active.value && props.product.paritMerakez){
            let merakez = props.product.paritMerakez
            let items = store.state.products.filter(itm => itm.paritMerakez == merakez && itm.makat != props.product.makat && itm.showOnWeb != 'N')
            return items
        }
    }))

    const items_in_sale = ref(computed(() => {
        if(props.product.sale){
            let items = [];
            let makats = props.product.sale.products;
            for(let i = 0; i < makats.length; i++){
                let inx = store.state.products.findIndex(it => {
                    return it.makat == makats[i].makat
                })
                if(inx != -1){
                    if(makats[i].makat != props.product.makat){
                        items.push(store.state.products[inx])
                    }
                }
            }
            return items.filter(itm => itm.makat != props.product.makat && itm.showOnWeb != 'N')
        }
    }))

    const close = (e) => {
        if(e.srcElement.className.includes('add-item-back') || e.srcElement.className.includes('close')){
            emit("close")
        }
    }

    watch(agent_options, async () => {
        if(agent_options.value){
            const pro_data = await projectFunctions.httpsCallable("get_product_stock")({makat: props.product.makat})
            agent_product_data.value.stock = pro_data.data.LOGCOUNTERS_SUBFORM[0].SELLBALANCE
            agent_product_data.value.shipments = pro_data.data.LOGCOUNTERS_SUBFORM[0].PORDERS
            if(!agent_product_data.value.shipments){
                agent_product_data.value.shipments = 'אין';
            }
            const order_data = await projectFunctions.httpsCallable("get_product_last_orders")({client_makat: user.value.client, makat: props.product.makat})
            if(order_data.data && order_data.data.PARTTRANSLAST2_SUBFORM.length > 0){
                agent_product_data.value.last_bought_date = new Date (order_data.data.PARTTRANSLAST2_SUBFORM[0].CURDATE).toLocaleDateString('he')
                agent_product_data.value.last_bought_amount = order_data.data.PARTTRANSLAST2_SUBFORM[0].TQUANT
                agent_product_data.value.last_bought_price = order_data.data.PARTTRANSLAST2_SUBFORM[0].ZROS_PRICE
                agent_product_data.value.last_bought_discount = order_data.data.PARTTRANSLAST2_SUBFORM[0].ZROS_PERCENT
                if(!agent_product_data.value.last_bought_discount){
                    agent_product_data.value.last_bought_discount = 'אין';
                }
                agent_product_data.value.last_orders = order_data.data.PARTTRANSLAST2_SUBFORM;
            }else{
                agent_product_data.value.last_bought_date = 'אין';
                agent_product_data.value.last_bought_amount = 'אין';
                agent_product_data.value.last_bought_price = 'אין';
                agent_product_data.value.last_bought_discount = 'אין';
            }
        }
    })
    

    const check_adding_type = (type) => {
        if(adding_type.value == type){
            return 'background:var(--success)'
        }else{
            return 'background:var(--alert-purple)'
        }
    }

    const get_item_kosher_doc = async () => {
        const doc = await projectFunctions.httpsCallable("get_item_attachments")({makat: props.product.makat})
        if(doc.data){
            let path = doc.data
            path.replace('system', '')
            path = doc.data.substring(doc.data.indexOf('mail/'), path.length)
            
            path = path.replace('mail/', '')
            path = 'https://erp.roslog.co.il/priority/primail/' + path
            window.open(path);
        }else{
            slide_pop_error("לא קיים קובץ")
        }
    }

    const order_amount_pcs = ref(0);
    const order_amount_cartons = ref(0);

    const update_order_amount = () => {
        let pcs = 0;
        if(adding_type.value == 'cartons'){
            pcs = order_amount_cartons.value * Number(props.product.boxSize)
            order_amount_pcs.value = pcs;
        }else{
            pcs = order_amount_pcs.value
            order_amount_cartons.value = pcs / Number(props.product.boxSize)
            order_amount_cartons.value = Number(order_amount_cartons.value.toFixed(2))
        }
        add_item_to_cart(props.product, Number(order_amount_pcs.value));
    }

    const clean_zeros = (e) => {
        e.target.placeholder = e.target.value
        e.target.value = ''
    }

    const gallery = ref(null)
    const gallery_location = ref(0)
    const show_light_box = ref(false)
    const handle_gallery_location = (direction)=>{
        if(direction=='plus'){
            if(gallery_location.value==gallery.value.length-1)gallery_location.value=0
            else gallery_location.value+=1
        }
        if(direction=='minus'){
            if(gallery_location.value==0)gallery_location.value=gallery.value.length-1
            else gallery_location.value-=1
        }
    }

    onMounted(() => {
        if(!props.product.order_amount){
            props.product.order_amount = 0;
        }else{
            let amount = props.product.order_amount;
            order_amount_cartons.value = (amount / (Number(props.product.boxSize))).toFixed(2);
            order_amount_cartons.value = Number(order_amount_cartons.value);
            order_amount_pcs.value = Number(amount)
        }
        if(props.product.prices && props.product.prices.length > 0){
            if(props.product.prices.length == 1 && props.product.prices[0].discount == 0.01){
            }else{
                merakez_active.value = true;
            }
        }
        if(props.product.shows){
            gallery.value = []
            gallery.value.push(props.product.image)
            props.product.shows.forEach(show=>{
                if(show.approved) gallery.value.push(show.url)
            })
        }
    })

    const open_new_item = (item) => {
        emit("open_new", item)
    }

    const handle_upload_new_image = async()=>{
        const { value: file } = await Swal.fire({
                title: 'העלה תמונה',
                input: 'file',
                showCancelButton: true,
                confirmButtonText:'אישור',
                cancelButtonText:'ביטול',
                inputAttributes: {
                    'accept': 'image/*',
                    'aria-label': 'תמונת תווית מוצר'
                }
                })

                if (file) {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        Swal.fire({
                        title: 'צפייה בתמונה',
                        imageUrl: e.target.result,
                        imageAlt: 'תווית המוצר',
                        showCancelButton: true,
                        confirmButtonText:'העלאת תמונה',
                        cancelButtonText:'ביטול',
                        }).then(async()=>{
                            await uploadImage(file,`Shows/${props.product.makat}/${agent_name.value}/${new Date().toLocaleDateString('he')}/${Math.random()}`)
                            try{
                                await save_uploaded_image_in_db()
                                alert('success','הצלחה','התמונה עלתה בהצלחה')
                            }catch(err){
                                alert('error','אירעה שגיאה',err.message)
                            }  
                        })
                    }
                    reader.readAsDataURL(file)
                }
    }

    const save_uploaded_image_in_db = async()=>{
        const show = {
            url:url.value,
            agent_name:agent_name.value,
            agent_id:agent_id.value,
            approved:false,
        }
        await projectFunctions.httpsCallable('add_show_to_product')({
            user:store.state.user,
            show,
            makat:props.product.makat
        })
    }

   

    return{
        agent_options, agent_product_data, adding_type, see_prices, user, merakez_active, items_in_sale, close,
        check_adding_type, get_item_kosher_doc, order_amount_cartons, order_amount_pcs, update_order_amount, open_new_item,
        items_on_discount, clean_zeros,handle_upload_new_image,save_uploaded_image_in_db,
        gallery,
        gallery_location,
        handle_gallery_location,show_light_box
    }
}
}
</script>

<style scoped>
.add-item-back{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.527);
    z-index: 10;
    animation: fade-in 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-btn{
    position: absolute;
    top: 55px;
    left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--danger);
    color: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 5;
}
.add-item-to-cart{
    position: relative;
    width: 98%;
    max-width: 560px;
    height:fit-content;
    max-height: 90%;
    background: white;
    border-radius: 10px;
    z-index: 10;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.671);
    animation: fade-in 0.25s;
}
.product-name{
    position: relative;
    width: 100%;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 22px;
    white-space: nowrap;
    background: var(--success);
    color: white;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.712);
}
.product-image{
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}
.left-arrow{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--purple);
    cursor: pointer;
    bottom: 5px;
    transform: translateY(-50%);
    right: 30%;
    z-index: 15;
}
.right-arrow{
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--purple);
    cursor: pointer;
    bottom: 5px;
    transform: translateY(-50%);
    left: 30%;
    z-index: 15;
}
.upload-img-btn{
    position: absolute;
    top: 120px;
    right: 0px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--purple);
    color: white;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    z-index: 2;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.596);
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}

.product-price{
    position: absolute;
    top: 10px;
    left: 50px;
    width: fit-content;
    height: fit-content;
    font-size: 40px;
    background: var(--success);
    padding: 5px;
    border-radius: 5px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.719);
}
.product-data{
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.data-line{
    flex-shrink: 0;
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 24px;
    align-items: center;
    padding-right: 10px;
}
.agent-options-btn{
    position: absolute;
    top: 180px;
    right: 0px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--purple);
    color: white;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    z-index: 2;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.596);
}
.agent-options-btn i {
    font-size: 40px;
}
.agent-options{
    width: 375px;
    background: white;
    height: 600px;
    position: absolute;
    z-index: 16;
    border-radius: 10px;
    background: var(--secondary);
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.agent-options-title{
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    font-size: 21px;
    flex-shrink: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--purple);
}
.close-agent-options{
    position: absolute;
    top: 5px;
    left: 5px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--danger);
    z-index: 2;
    cursor: pointer;
}
.agent-option-line{
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 5px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    color: whitesmoke;
    align-items: center;
    padding-right: 5px;
}
.agent-option-line p {
    width: 50%;
}
.agent-option-line :first-child{
    color: var(--yellow);
}
.last-orders-grid{
    width: 100%;
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding: 5px;
}
.client-order{
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    background-color: var(--alert-purple);
    color: whitesmoke;
    margin-bottom: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
}
.product-on-sale{
    position: absolute;
    top: 55px;
    right: 0px;
    width: fit-content;
    min-width: 100px;
    font-size: 26px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
    padding: 5px 10px 5px 10px;
    background: var(--danger);
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.514);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.356);
}
.adding-type{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}
.add-type-btn{
    height: 90%;
    background: var(--alert-purple);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;
    margin-right: 10px;
    cursor: pointer;
}
.note{
    margin-top: 5px;
    width: 100%;
    height: fit-content;
    text-align: center;
    color: var(--danger);
}
.add-amount{
    width: 100%;
    height: 60px;
    text-align: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.product-sale{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
.sale-label{
    flex-shrink: 0;
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    background: var(--danger);
    color: white;
    text-shadow: 0 0 3px black;
    margin-bottom: 5px;
}
.sale-end-date{
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    font-size: 16px;
    background: var(--success);
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
}
.sale-description{
    width: 100%;
    height: fit-content;
    text-align: center;
    margin-bottom: 5px;
}
.sale-prices-table{
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
}
#table {
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:hover {background-color: #ddd;}
#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.more-sale-items-title{
    margin-top: 5px;
    width: 100%;
    text-align: center;
    background: var(--danger);
    color: whitesmoke;
}
.more-products-on-sale{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-x: auto;
    padding: 5px 0 5px 0;
    flex-wrap: wrap;
}
.item-card-sale{
    margin-top: 8px;
    width: 160px;
    height: 160px;
    margin-right: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3px;
    background: whitesmoke;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.555);
    cursor: pointer;
    position: relative;

}
.item-sale-card-image{
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}
.item-sale-card-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}
.pr-sl-makat{
    position: absolute;
    top: 5px;
    left: 5px;
    background: var(--purple);
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    color: whitesmoke;
    text-shadow: 0 0 3px rgba(128, 128, 128, 0.363);
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.363);
}
.order-amount{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: var(--yellow);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.692);
    flex-direction: column;
}
.sale-price{
    background: var(--danger);
    transition: 0.25s;
}

.light-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    z-index: 20;
    
}
.images-box{
    position: relative;
    width: 100%;
    max-width:500px;
    height: 100%;
    max-height: 500px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.images-box img{
    max-width: 100%;
    max-height: 100%;
}
.close-light-box{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--success);
    color: var(--light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;
}


</style>