<template>
    <div class="client-cart" v-if="show_cart" @click="close_cart">
        <ClientCart @close="show_cart = !show_cart"/>
    </div>
    <div class="shopping-cart-btn" @click="open_cart" id="cart-btn" v-if="cart && cart.items.length > 0">
        <img src="@/assets/shopping-cart.png" alt="">
        <div class="cart-counter">
            <p>{{cart.items.length}}</p>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
import store from '../../store'
import ClientCart from './ClientCart.vue';
import router from '../../router';

export default {
components:{ClientCart},
setup(){
    const show_cart = ref(false);

    const cart = ref(computed(() => {
        return store.getters.client_cart
    }))

    const open_cart = () => {
        const el = document.getElementById('cart-btn')
        if(el){
            el.classList.add('amination');
            show_cart.value = true;
            setTimeout(() => {
                el.classList.remove('amination')
            }, 1550);
        }
    }

    const close_cart = (e) => {
        if(e.srcElement.className.includes('client-cart')){
            show_cart.value = false;
        }
    }

    watch(router.currentRoute, () => {
        let route = router.currentRoute.value;
        if(route.fullPath.includes('checkout')){
            if(window.innerWidth < 650){
                const el = document.getElementById('cart-btn')
                if(el){
                    el.style.top = '120px';
                    el.style.bottom = 'unset';
                    el.style.position = 'absolute'
                }
            }
        }else{
            if(window.innerWidth < 650){
                const el = document.getElementById('cart-btn')
                if(el){
                    el.style.top = 'unset';
                    el.style.bottom = '10px';
                    el.style.position = 'fixed'
                }
            }
        }
        if(route.fullPath.includes('routetoday') || route.fullPath.includes('admin') || route.fullPath.includes('bi')){
            const el = document.getElementById('cart-btn')
            if(el){
                el.style.display = 'none'
            }
        }else{
            const el = document.getElementById('cart-btn')
            if(el && el.style.display == 'none'){
                el.style.display = 'flex'
            }
        }
    })
    return{
        open_cart, cart, show_cart, close_cart
    }
}
}
</script>

<style scoped>
.client-cart{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.589);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cart-data{
    width: 98%;
    max-width: 500px;
    height: calc(100% - 100px);
    background: var(--light);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.742);
    overflow: hidden;
}
.shopping-cart-btn{
    position: fixed;
    z-index: 2;
    bottom: 10px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60px;
    height: 60px;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    transition: 0.25s;
    border-radius: 10px;
}
.amination{
    animation: drive-out 1.5s;
}
.shopping-cart-btn img{
    max-width: 100%;
    max-height: 100%;
}
.cart-counter{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    right: 10px;
    background: var(--danger);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@keyframes drive-out {
    0%{
        left: 10px;
    }
    100%{
        left:  110%;
    }
}

</style>