import Swal from "sweetalert2"
import {slide_pop_error, slide_pop_successs} from './Msgs';
import {projectAuth, projectFirestore, projectFunctions} from '../firebase/config';
import store from '../store';
import { computed, ref } from "vue";


const user = ref(computed(() => {
    return store.getters.user
}))

const update_adress = (user) => {
    if(!user.client && !Number(user.agent) > 0){
        Swal.fire({
            icon: 'question',
            title: 'עדכון כתובת למשלוח',
            text: 'אנא מלא את השדות הבאים בדיוק מירבי',
            html:   '<div style="width:100%; margin: none;">' +
                    '<p style="max-width:100%; text-align:center;"> טרם עדכנת את כתובת המשלוח שלך<br> אנא עדכן כעת:</p>' +
                    '<input id="city" class="swal2-input" placeholder="עיר" style="padding:0; text-align:center;">' +
                    '<input id="adress" class="swal2-input" placeholder="רחוב ומספר" style="padding:0; text-align:center;">' +
                    '<input id="postal_code" class="swal2-input" placeholder="מיקוד" style="padding:0; text-align:center;">' +
                    '<input id="floor" class="swal2-input" placeholder="קומה" style="padding:0; text-align:center;">' +
                    '</div>',
            focusConfirm: false,
            preConfirm: () => {
                let adress={
                    city: document.getElementById('city').value,
                    adress: document.getElementById('adress').value,
                    postal_code: document.getElementById('postal_code').value,
                    floor: document.getElementById('floor').value,
                } 
                if(!adress.city || !adress.adress ||!adress.postal_code ||!adress.floor){
                    slide_pop_error('אנא מלא את כלל השדות!');
                    return false
                }else{
                    slide_pop_successs('מעדכנים את הכתובת כעת...')
                    return [adress]
                }
                
            },
            showCancelButton: true,
            cancelButtonText: 'מאוחר יותר',
            confirmButtonText: 'עדכן'
        }).then(async (res) => {
            if(res.isConfirmed){
                await projectFunctions.httpsCallable('update_profile')({type: 'adress',adress: res.value[0], uid: projectAuth.currentUser.uid});
                store.dispatch("refresh_profile");
                slide_pop_successs('הכתובת עודכנה בהצלחה!')
            }
        })
    }else{
        Swal.fire({
            icon: 'info',
            title: 'לקוח עסקי',
            text: 'לעדכון כתובת של לקוח עסקי נא לפנות למשרדי החברה.'
        })
    }

}

const collect_location_data = async () => {
    if(user.value && user.value.agent && Number(user.value.agent) > 0 && Number(user.value.agent) < 50){
        let cords = null
        let succeed = async (pos) => {
            if(user.value){
                let lng = pos.coords.longitude;
                let lat = pos.coords.latitude;
                cords = {lng:lng, lat:lat}
                if(store.state.user.cords){
                    let _cords = store.state.user.cords
                    if(_cords.lat != cords.lat || _cords.lng != cords.lng){
                        await projectFirestore.collection('Locations').doc(projectAuth.currentUser.uid).set({
                            date: new Date(),
                            lat: cords.lat,
                            lng: cords.lng,
                            uid: projectAuth.currentUser.uid
                          })
                          store.state.user.cords = cords
                    }
                }else{
                    await projectFirestore.collection('Locations').doc(projectAuth.currentUser.uid).set({
                        date: new Date(),
                        lat: cords.lat,
                        lng: cords.lng,
                        uid: projectAuth.currentUser.uid
                      })
                      store.state.user.cords = cords
                }
            }
        }
        let failure = (err) => {
          console.log(err);
        }
        let options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximunAge: 0
        }
        var interval = setInterval(() => {
          navigator.geolocation.getCurrentPosition(succeed, failure, options)
          if(!projectAuth.currentUser){
            clearInterval(interval)
          }
        }, 5000);
    }
}


const update_user_push_token = async () => {
    let uid = user.value.uid
    let currentToken = store.state.push_token
    let token_arr = user.value.push_token

    const inx = token_arr.findIndex(token=>token==currentToken)
    if(inx == -1){
        token_arr.push(currentToken);
        projectFunctions.httpsCallable("set_push_token")({uid: uid, tokens_array: token_arr})
    }
}

export { update_adress, collect_location_data,update_user_push_token}
